import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { PaymentAccountInfo, PayrollSummary } from "@src/lib/interface";
import request from "superagent";

import { Balance, ClipboardPaySchedule } from "./models";
import { getAuthHeader } from "../superagent";

const logAndReRaiseError = (error) => {
  logApiFailureEvent(error);
  throw error;
};

const fetchPayrollData = async (queryData: object): Promise<any> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/payroll/mobile/v2`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ body }) => body)
    .catch(logAndReRaiseError);
};

const getShiftsDetailsForBonuses = async (shiftIds: string[], projection): Promise<any> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/details`)
    .set(await getAuthHeader())
    .send({ shiftIds, projection })
    .then(({ body }) => body)
    .catch(logAndReRaiseError);
};

const fetchLifeTimeEarnings = async (): Promise<PayrollSummary> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/payroll/summary`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logAndReRaiseError);
};

const fetchBalance = async (): Promise<Balance> => {
  return await request
    .get(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/authenticated/balance`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logAndReRaiseError);
};

const payout = async (): Promise<void> => {
  return await request
    .post(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/authenticated/payout`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logAndReRaiseError);
};

const createLoginLink = async (redirectUrl: string): Promise<string> => {
  try {
    return await request
      .post(
        `${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/authenticated/create-login-link`
      )
      .send({ redirectUrl })
      .set(await getAuthHeader())
      .then(({ text }) => text);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const updatePaymentSchedule = async (data: { interval: string }): Promise<PaymentAccountInfo> => {
  try {
    return await request
      .put(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/authenticated/schedule`)
      .send({ interval: data.interval })
      .set(await getAuthHeader())
      .then(({ body }) => body);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const getClipboardPaySchedule = async (workerId: string): Promise<ClipboardPaySchedule> => {
  try {
    return await request
      .get(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/${workerId}/pay-schedule`)
      .set(await getAuthHeader())
      .then((res) => {
        return res.text as ClipboardPaySchedule;
      });
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export {
  fetchPayrollData,
  getShiftsDetailsForBonuses,
  fetchLifeTimeEarnings,
  fetchBalance,
  payout,
  createLoginLink,
  updatePaymentSchedule,
};
