export enum BONUS_TYPE {
  TBIO_BACK_TO_BACK = "TBIO.BACK_TO_BACK",
  TBIO_SINGLE = "TBIO.SINGLE",
  TBIO_OVER_FORTY = "TBIO.OVER_FORTY",
}

export enum PAYMENT_STATUS {
  PENDING = "PENDING",
  PARTIAL = "PARTIAL",
  PAID = "PAID",
}
