import { IonButton, IonIcon } from "@ionic/react";
import { chevronDown, chevronForward } from "ionicons/icons";
import { FC } from "react";

import { ChevronProps } from "./models";

export const Chevron: FC<ChevronProps> = ({ expanded, setExpanded, description, ariaLabel }) => {
  return (
    <IonButton
      onClick={() => setExpanded(!expanded)}
      data-testid={`chevron-${description}`}
      shape="round"
      fill="clear"
      size="small"
      aria-label={ariaLabel}
    >
      <IonIcon style={{ color: "gray" }} icon={expanded ? chevronDown : chevronForward} />
    </IonButton>
  );
};
