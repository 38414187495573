import "./style.scss";

import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import { BonusList, PayrollLineItem, PayrollList } from "@src/lib/interface";
import { weekBoundsFromDate } from "@src/lib/utils";
import { arrowBack, arrowForward, stopwatchOutline } from "ionicons/icons";
import moment, { Moment } from "moment-timezone";
import { FC } from "react";

import { formatDollarsAsUsd } from "../../utils/currency";
import { pluralHours } from "../utils/strings";

const formatDate = (date: Moment | string) => moment(date).format("MMMM Do, YYYY");
const formatTime = (date: Moment | string) => moment(date).format("HH:mm a");

export interface ShiftListHeaderProps {
  handleBackClick: () => void;
  handleForwardClick: () => void;
  relativeWeek: number;
  items: PayrollLineItem[];
}

export function LifetimeEarningsDropdown(props: ShiftListHeaderProps) {
  const { handleBackClick, handleForwardClick, relativeWeek, items } = props;
  const getWeekRange = (relativeWeek: number) => {
    const { start, end } = weekBoundsFromDate(
      moment().add(relativeWeek, "weeks"),
      "America/Los_Angeles"
    );
    const after = start.format("MMMM D");
    const before = end.format("MMMM D, YYYY");
    return `${after} - ${before}`;
  };

  return (
    <>
      <div>
        <IonRow className="details-nav">
          <IonButton
            shape="round"
            fill="clear"
            color="light"
            size="small"
            onClick={handleBackClick}
          >
            <IonIcon icon={arrowBack} />
          </IonButton>
          <div style={{ fontWeight: "bold" }}>{getWeekRange(relativeWeek)}</div>
          <IonButton
            shape="round"
            fill="clear"
            color="light"
            size="small"
            disabled={relativeWeek >= 0}
            onClick={handleForwardClick}
          >
            <IonIcon icon={arrowForward} />
          </IonButton>
        </IonRow>
      </div>

      <div className="details-container alt">
        {items.map((item) =>
          item.type === "BONUS" ? (
            <BonusListItem key={`bonus_${item._id}`} bonus={item} />
          ) : (
            <ShiftListItem key={item._id} shift={item} />
          )
        )}

        {!items.length && (
          <div className="empty-shifts">
            We don’t have records of you working shifts for the week of {getWeekRange(relativeWeek)}
          </div>
        )}

        {items
          .filter((item) => item.type === "SHIFT")
          .some((shift) => !(shift as PayrollList).isStripePayment) && (
          <div className="asterisk">
            <i>
              * Shifts with an asterisk on the side of the payment status were paid via DailyPay
            </i>
          </div>
        )}
      </div>
    </>
  );
}

const totalAmount = (shift: PayrollList): number => {
  if (shift.isStripePayment) {
    return (shift.paymentStatus?.totalAmount || 0) / 100;
  }
  if (shift.instantPayDetails) {
    return shift.instantPayDetails.totalAmount || 0;
  }
  return shift.totalAmount;
};

const paidAmount = (shift: PayrollList): number => {
  if (shift.isStripePayment) {
    return (shift.paymentStatus?.amountPaid || 0) / 100;
  }
  if (shift.instantPayDetails) {
    return shift.instantPayDetails.paidAmount || 0;
  }
  return shift.totalAmount;
};

const ShiftListItem: FC<{ shift: PayrollList }> = ({ shift }) => {
  return (
    <>
      <div className="details nav-space">
        <IonCol size="7" style={{ textAlign: "start" }}>
          <div style={{ fontSize: 13, color: "#353535" }}> {formatDate(shift.start)}</div>
          <div style={{ fontSize: 13, color: "#353535" }}>
            {formatTime(shift.start)} - {formatTime(shift.end)} ({Number(shift.time.toFixed(2))}{" "}
            {pluralHours(shift)})
          </div>
          <div style={{ color: "#777777" }}>{shift.facility.name}</div>
        </IonCol>
        {paidAmount(shift) >= totalAmount(shift) ? (
          <IonCol className="badge-container" size="5" style={{ textAlign: "center" }}>
            <div className="paid-badge full-paid">Full Paid {!shift.isStripePayment && "*"}</div>
            <div className="amount full-paid-amount">{formatDollarsAsUsd(paidAmount(shift))}</div>
          </IonCol>
        ) : (
          <IonCol className="badge-container" size="5" style={{ textAlign: "center" }}>
            <div className="paid-badge partially-paid">
              Partially Paid {!shift.isStripePayment && "*"}
            </div>
            <div className="partially-paid-amount">
              <span className="partially-paid-left">
                {formatDollarsAsUsd(totalAmount(shift))} Total
              </span>
              <br></br>
              <span className="partially-paid-amount">
                {formatDollarsAsUsd(paidAmount(shift))} Paid
              </span>
            </div>
          </IonCol>
        )}
      </div>
    </>
  );
};

const BonusListItem: FC<{ bonus: BonusList }> = ({ bonus }) => {
  const toHumanReadable = (string: string) =>
    string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();

  return (
    <>
      <div className="details nav-space">
        <IonCol size="7" style={{ textAlign: "start" }}>
          <div style={{ fontSize: 13, color: "#353535" }}>
            {" "}
            {formatDate(bonus.payPeriodStart || bonus.createdAt)}
          </div>
          <div style={{ fontSize: 13, color: "#353535" }}>
            {bonus.originalReason === "Extra Time Pay" ? (
              <div className="v-center">
                <IonIcon icon={stopwatchOutline} className="lifetime_earning_stopwatch" />
                <span>Extra Time Pay</span>
              </div>
            ) : (
              "Bonus Payment"
            )}
          </div>
          <div className="bonus-reason">{bonus.reason}</div>
          {isDefined(bonus.facility?.name) ? (
            <div style={{ color: "#aaa" }}>{bonus.facility?.name}</div>
          ) : null}
        </IonCol>
        <IonCol className="badge-container" size="5" style={{ textAlign: "center" }}>
          <div className={`paid-badge ${bonus.status === "PAID" ? "full-paid" : "partially-paid"}`}>
            {toHumanReadable(bonus.status)}
          </div>
          <div className={`amount ${bonus.status === "PAID" ? "paid-amount" : "pending-amount"}`}>
            {formatDollarsAsUsd(bonus.amount)}
          </div>
        </IonCol>
      </div>
    </>
  );
};
