import styled from "@emotion/styled";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { FC, useState } from "react";

import { Chevron } from "./chevron";
import { colors } from "./colors";
import { BubbleProps } from "./models";

const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const BubbleContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(156, 156, 156, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

export const Bubble: FC<BubbleProps> = ({
  icone,
  description,
  value,
  dropdown,
  storeHideDropDown,
  valueFontSize,
  loading,
}) => {
  const [showDrop, setShowDrop] = useState(false);

  storeHideDropDown && storeHideDropDown(() => setShowDrop(false));

  return (
    <BubbleContainer className="bubbleContainer">
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ padding: 8 }}>
          <IonCol size="2" style={center}>
            <div className="icon-background">
              <IonIcon icon={icone} />
            </div>
          </IonCol>
          <IonCol size={dropdown ? "8" : "10"} style={{ textAlign: "left" }}>
            <small style={{ color: colors.gray3, fontSize: 12 }}>{description}</small>
            <h2 style={{ color: colors.gray1, fontSize: valueFontSize || 18 }}>
              {loading ? "Loading" : value}
            </h2>
          </IonCol>
          {dropdown && (
            <IonCol size="2" style={center}>
              <Chevron
                setExpanded={setShowDrop}
                expanded={showDrop}
                description={description}
                ariaLabel={`Toggle ${description}`}
              />
            </IonCol>
          )}
        </IonRow>
        {showDrop && dropdown}
      </IonGrid>
    </BubbleContainer>
  );
};
